// index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import RecoverySiteComponents from './RecoverySiteComponents';

console.log('React initialization starting...');

const container = document.getElementById('react-root');
if (container) {
  console.log('React root container found');
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <RecoverySiteComponents />
    </React.StrictMode>
  );
  console.log('React components rendered');
} else {
  console.error('React root container not found!');
}